import React, { Component } from 'react';
import { Layout, Button, Icon, Form, Input, Collapse, Radio, InputNumber, message  } from "antd";
import QuizService from '../../../../services/Quiz';
import './add-quiz.scss';

const { Content, Header } = Layout;
const { Panel } = Collapse;

class AddQuizFrom extends Component {
    id = 0

    remove = (k) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        if (keys.length === 1) {
            return;
        }

        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    }

    add = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(this.id++);
        form.setFieldsValue({
            keys: nextKeys,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { keys } = values;
                if (keys.length === 0) {
                    message.error('O Quiz deve ter ao menos uma pergunta!');
                } else {
                    const questions = [];
                    const fields = ['question', 'item1', 'item2', 'item3', 'item4', 'correct'];
                    keys.forEach((_, i) => {
                        const question = {};
                        fields.forEach(field => {
                            question[field] = values[field][i];
                        });
                        questions.push(question);
                    });

                    for (const field of fields) {
                        delete values[field];
                    }

                    delete values['keys'];

                    const cancelLoading = message.loading('Criando novo quiz...');

                    QuizService.create({
                        ...values,
                        questions
                    })
                    .then(() => {
                        message.success('Quiz salvo com sucesso!');
                    })
                    .finally(() => {
                        cancelLoading();
                    });
                }
            }
        });
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20 },
            },
        };
        getFieldDecorator('keys', { initialValue: [] });
        const keys = getFieldValue('keys');

        const formItems = keys.map((k, index) => (
            <div key={k} className="ps-quiz-question-form-container">
                <Collapse className="ps-quiz-question-form-collapser">
                    <Panel header={`Questão ${index + 1}`}>
                        <Form.Item
                            {...formItemLayout}
                            label="Título"
                            required={false}
                        >
                            {getFieldDecorator(`question[${k}]`, {
                                rules: [{ required: true, message: 'Campo obrigatório' }]
                            })(
                                <Input placeholder="Título" style={{ width: '60%', marginRight: 8 }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label="Item 1"
                            required={false}
                        >
                            {getFieldDecorator(`item1[${k}]`, {
                                rules: [{ required: true, message: 'Campo obrigatório' }]
                            })(
                                <Input style={{ width: '60%', marginRight: 8 }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label="Item 2"
                            required={false}
                        >
                            {getFieldDecorator(`item2[${k}]`, {
                                rules: [{ required: true, message: 'Campo obrigatório' }]
                            })(
                                <Input style={{ width: '60%', marginRight: 8 }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label="Item 3"
                            required={false}
                        >
                            {getFieldDecorator(`item3[${k}]`, {
                                rules: [{ required: true, message: 'Campo obrigatório' }]
                            })(
                                <Input style={{ width: '60%', marginRight: 8 }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label="Item 4"
                            required={false}
                        >
                            {getFieldDecorator(`item4[${k}]`, {
                                rules: [{ required: true, message: 'Campo obrigatório' }]
                            })(
                                <Input style={{ width: '60%', marginRight: 8 }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label="Item correto"
                            required={false}
                        >
                            {getFieldDecorator(`correct[${k}]`, {
                                rules: [{ required: true, message: 'Campo obrigatório' }]
                            })(
                                <Radio.Group>
                                    <Radio value={1}>item 1</Radio>
                                    <Radio value={2}>item 2</Radio>
                                    <Radio value={3}>item 3</Radio>
                                    <Radio value={4}>item 4</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Panel>
                </Collapse>
                <Icon
                    className="ps-question-delete-button"
                    type="close-circle"
                    onClick={() => this.remove(k)}
                />
            </div>
        ));
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item {...formItemLayout} label="Título">
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Por favor, insira o título!' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Descrição">
                    {getFieldDecorator('description', {
                        rules: [{ required: true, message: 'Insira a descrição!' }]
                    })(<Input.TextArea />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Assunto principal">
                    {getFieldDecorator('subject', {
                        rules: [{ required: true, message: 'Insira o assunto!' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Score">
                    {getFieldDecorator('score', {
                        rules: [{ required: true, message: 'Insira o score!' }]
                    })(<InputNumber min={0} />)}
                </Form.Item>
                {formItems}
                <Form.Item {...formItemLayoutWithOutLabel}>
                    <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                        <Icon type="plus" /> Adicionar questão
                    </Button>
                </Form.Item>
                <Form.Item {...formItemLayoutWithOutLabel}>
                    <Button type="primary" htmlType="submit">Criar</Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedAddQuizFrom = Form.create({ name: 'question_form_item' })(AddQuizFrom);

class AddQuizPage extends Component {
    render() {
        return (
            <Layout className="ps-admin-content">
                <Header>
                    <span>Cadastre um novo quiz</span>
                </Header>
                <Content>
                    <div className="pd-add-quiz-container">
                        <WrappedAddQuizFrom />
                    </div>
                </Content>
            </Layout>
        );
    }
}


export default AddQuizPage;
