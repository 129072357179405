import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { message, Layout, Table, Popconfirm, Button, Icon, Divider } from "antd";
import QuizService from '../../../../services/Quiz';
const { Content, Header } = Layout;

const QuizzesPage = () => {
    const [quizzes, setQuizzes] = useState([]);
    const [loading, setLoading] = useState(true);

    function fetchQuizzes() {
        setLoading(true);
        QuizService.getAll()
        .then(response => {
            setLoading(false);
            setQuizzes(response.data.quizzes);
        });
    }

    function removeQuiz(quizId) {
        const cancelLoading = message.loading('Removendo quiz...');
        QuizService.remove(quizId)
        .then(() => {
            cancelLoading();
            fetchQuizzes();
            message.success('Quiz removida com sucesso.');
        })
        .finally(() => {
            cancelLoading();
        });
    };

    useEffect(() => {
        fetchQuizzes();
    }, []);

    return (
        <Content className="ps-admin-content">
            <Header className="pt-3">
                <h3 className="h5 d-block">Quizzes</h3>
                <Link className="d-block" to="/quizzes/adicionar">
                    <Button type="primary">
                        Adicionar
                        <Icon type="plus" />
                    </Button>
                </Link>
            </Header>
            <Table
                loading={loading}
                pagination={false}
                dataSource={quizzes}
                columns={[{
                    title: 'Nome',
                    dataIndex: 'name',
                    key: 'name',
                }, {
                    title: 'Descrição',
                    dataIndex: 'description',
                    key: 'description',
                }, {
                    title: 'Assunto',
                    dataIndex: 'subject',
                    key: 'subject',
                }, {
                    title: 'Score',
                    dataIndex: 'score',
                    key: 'score',
                }, {
                    title: 'Ações',
                    key: 'action',
                    render: (text, item) => (
                      <span>
                          <Link to={`/quizzes/${item.id}`}>
                              <Button type='link'>Editar</Button>
                          </Link>
                          <Divider type='vertical' />
                          <Popconfirm
                              title="Deseja mesmo remover esse quiz?"
                              onConfirm={() => removeQuiz(item.id)}
                              okText="Sim"
                              cancelText="Não"
                          >
                              <Button type='link'>Remover</Button>
                          </Popconfirm>
                      </span>
                    )
                  }]}
            />
        </Content>
    );
};

export default QuizzesPage;